<template>
  <validation-observer ref="job-economic-conditions-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Forma de pago"
                label-for="job-type-payment"
              >
                <BaseSelect
                  id="job-type-payment"
                  :value="model.type_payment"
                  :resource="$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS"
                  @input="update('type_payment', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Hitos de facturación"
                label-for="job-billing-milestone"
              >
                <b-form-input
                  id="job-billing-milestone"
                  :value="model.billing_milestone"
                  placeholder="Hitos de facturación"
                  @input="update('billing_milestone', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Avales y garantías"
                label-for="job-endorsements-and-guarantees"
              >
                <b-form-input
                  id="job-endorsements-and-guarantees"
                  :value="model.endorsements_and_guarantees"
                  placeholder="Avales y garantías"
                  @input="update('endorsements_and_guarantees', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Tipo de impuesto"
                label-for="client-iva"
              >
                <TaxesSelect
                  :value="model.tax"
                  @input="update('tax', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Condiciones de retenciones"
                label-for="job-withholding-conditions"
              >
                <b-form-input
                  id="job-withholding-conditions"
                  :value="model.withholding_conditions"
                  placeholder="Condiciones de retenciones"
                  @input="update('withholding_conditions', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Adjuntar archivos"
                label-for="job-documentation"
              >
                <FileDropZone
                  id="job-documentation"
                  ref="documentation-input"
                  :files="files"
                  @removed-file="removedFiles.push($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import TaxesSelect from '@/components/tax/select/TaxesSelect.vue'

export default {
  name: 'JobEconomicConditionsForm',
  components: { BaseSelect, FileDropZone, TaxesSelect },
  mixins: [FormMixin],
  data() {
    return {
      removedFiles: [],
    }
  },
  computed: {
    files() {
      return this.model?.documentation || []
    },
  },
  methods: {
    getDocumentationData() {
      return { documentation: this.$refs['documentation-input'].getFiles(), removedFiles: this.removedFiles }
    },
  },
}
</script>

<style scoped>
</style>
