<template>
  <div>
    <OrderProfileCard />
    <BaseDocumentationCard
      v-model="documentation"
      :resource="order"
      :observations="observations"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
    />
    <OrderLinesTable />
    <b-card no-body>
      <b-row class="p-2" align-v="center" align-h="between">
        <b-col cols="auto">
          <span class="text-uppercase font-weight-bold mb-0">
            facturas asociadas
          </span>
        </b-col>
        <b-col cols="auto">
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
              resourceAction:
                $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
            }"
            @click="handleAddInvoiceButtonClick"
          >
            <b-button variant="primary" class="text-nowrap rounded-sm px-2">
              + Añadir factura proveedor
            </b-button>
          </b-link>
        </b-col>
      </b-row>
      <PurchaseInvoicesTable
        ref="purchase-invoices-table"
        :order-id="orderId"
        :loading="purchaseInvoicesLoading"
        hide-toolbar
        footer-totals-visible
      />
    </b-card>
    <b-card no-body>
      <b-row class="p-2" align-v="center" align-h="between">
        <b-col cols="auto">
          <span class="text-uppercase font-weight-bold mb-0">
            albaranes asociados
          </span>
        </b-col>
        <b-col cols="auto">
          <b-link>
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAddDeliveryNotesButtonClick"
            >
              + Añadir albaran
            </b-button>
          </b-link>
        </b-col>
      </b-row>
      <DeliveryNotesTable
        ref="delivery-notes-table"
        :order-id="orderId"
        :loading="loading"
        hide-toolbar
        disable-pagination
        footer-totals-visible
      />
    </b-card>
  </div>
</template>

<script>
import DeliveryNotesApi from "@/api/delivery-notes-api";
import { mapActions, mapGetters } from "vuex";
import OrderProfileCard from "@/components/orders/card/OrderProfileCard.vue";
import BaseDocumentationCard from "@/components/ui/card/BaseDocumentationCard.vue";
import OrderLinesTable from "@/components/orders/table/OrderLinesTable.vue";
import PurchaseInvoicesTable from "@/components/purchase-invoices/table/PurchaseInvoicesTable.vue";
import DeliveryNotesTable from "@/components/delivery-notes/table/DeliveryNotesTable.vue";
import PurchaseInvoicesApi from "@/api/purchase-invoices-api";
import OrdersApi from "@/api/orders-api";

export default {
  components: {
    DeliveryNotesTable,
    PurchaseInvoicesTable,
    OrderLinesTable,
    BaseDocumentationCard,
    OrderProfileCard,
  },
  data() {
    return {
      purchaseInvoicesLoading: false,
      orderId: this.$route.params.id.toString(),
      loading: false,
    };
  },
  computed: {
    ...mapGetters("orders", {
      order: "getOrder",
    }),
    documentation: {
      get() {
        return this.order?.documentation || [];
      },
      set(value) {
        this.setOrderDocumentation(value);
      },
    },
    observations() {
      return this.order?.observations || "Sin observaciones";
    },
    deleteEndPoint() {
      return this.order ? `/orders/delete_doc/${this.order.id}` : null;
    },
    updateEndPoint() {
      return this.order ? `/orders/${this.order.id}` : null;
    },
    jobId() {
      return this.$route.query["job-id"] || null;
    },
  },
  methods: {
    ...mapActions("orders", ["loadOrder", "setOrderDocumentation"]),
    ...mapActions("app", ["setLoading"]),
    async loadOrderDetails() {
      this.setLoading(true);
      try {
        await this.loadOrder(this.$route.params.id);

        if (this.jobId && !this.order.confirmation) {
          const response = await this.$modal.show({
            text: `${this.order.confirmation_message}`,
          });

          if (response.isConfirmed) {
            await this.updateOrder({ confirmation: 1 });
          }

          if (!response.isConfirmed) {
            await this.deleteOrder();
            this.$router.push({ name: "viewJob", params: { id: this.jobId } });
          }
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "Ocurrió un error al obtener el pedido. Por favor inténtelo de nuevo mas tarde."
        );
      }
      this.setLoading(false);
    },
    async updateOrder(order) {
      await OrdersApi.edit(this.order.id, order);
      this.loadOrderDetails();
    },
    async deleteOrder() {
      await OrdersApi.delete(this.order.id);
    },
    async handleAddDeliveryNotesButtonClick() {
      this.loading = true;
      try {
        await DeliveryNotesApi.createFormOrder(this.$route.params.id);
        await this.$refs["delivery-notes-table"].loadData();
      } finally {
        this.loading = false;
      }
    },
    handleBackButtonClick() {
      if (this.jobId) {
        return this.$router.push({
          name: "viewJob",
          params: { id: this.jobId },
        });
      }

      return this.$router.push({ name: "orders" });
    },
    async handleAddInvoiceButtonClick() {
      this.purchaseInvoicesLoading = true;
      try {
        await PurchaseInvoicesApi.verifyOrder(this.$route.params.id);
        await this.addPurchaseInvoiceToOrder();
      } catch (error) {
        await this.handleAddInvoiceError(error);
      }
      this.purchaseInvoicesLoading = false;
    },
    async addPurchaseInvoiceToOrder() {
      await PurchaseInvoicesApi.addPurchaseInvoiceToOrder(
        this.$route.params.id
      );
      await this.$refs["purchase-invoices-table"].loadData();
      this.$toast("Factura añadida correctamente");
    },
    async handleAddInvoiceError(error) {
      if (error.response?.status !== 403) {
        this.$toast.error(
          "Ocurrio un error al añadir la factura. Por favor inténtelo de nuevo mas tarde."
        );

        return;
      }

      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "El sumatorio del importe de las facturas registradas no puede ser mayor que el importe del pedido.",
        confirmButtonText: "Continuar Igualmente",
      });

      if (!response.isConfirmed) {
        return;
      }

      await this.addPurchaseInvoiceToOrder();
    },
  },
  beforeMount() {
    /*evita que al cargar el editar una factura de proveedor
    aparezca la vista incorrecta de edicion ya que esto se define
    por los tabs en la vista de purchase invoices
     - 0 para facturas con job
     - 1 para facturas sin job
    En esta vista siempre tiene que cargar facturas con jobs
    sin ningun filtro
    */
    this.$store.commit("purchaseInvoice/setFilters", {});
    this.$store.commit("purchaseInvoice/setTabSelected", 0);
  },
  async mounted() {
    await this.loadOrderDetails();
  },
};
</script>

<style scoped></style>
