<template>
  <validation-observer ref="provider-billing-form">
    <b-form ref="provider-billing-form" class="p-2" @submit.prevent>
      <b-row>
        <b-col sm="12" md="6">
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Forma de pago"
                label-for="provider-type-payment"
              >
                <BaseSelect
                  id="provider-type-payment"
                  :value="model.type_payment"
                  :resource="$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS"
                  @input="update('type_payment', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="IBAN" label-for="provider-iban">
                <b-form-input
                  :value="model.iban"
                  name="provider-iban"
                  placeholder="IBAN"
                  maxlength="30"
                  @input="update('iban', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="SWIFT" label-for="provider-swift">
                <b-form-input
                  :value="model.swift"
                  name="provider-swift"
                  placeholder="SWIFT"
                  @input="update('swift', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="IRPF" label-for="provider-irpf">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :value="model.irpf_percent"
                    name="provider-irpf"
                    placeholder="IRPF"
                    type="number"
                    @input="update('irpf_percent', $event)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="6">
          <b-row align-h="center">
            <b-col sm="6">
              <b-form-group label="Tipo de impuesto" label-for="provider-iva">
                <TaxesSelect
                  :value="model.tax"
                  @input="update('tax', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Recargo" label-for="provider-recharge">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :value="model.surcharge"
                    name="provider-recharge"
                    placeholder="Recargo"
                    type="number"
                    @input="update('surcharge', $event)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import TaxesSelect from "@/components/tax/select/TaxesSelect.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import FormMixin from "@/mixins/formMixin";

export default {
  name: "ProviderBillingForm",
  components: { BaseSelect, TaxesSelect },
  mixins: [FormMixin],
  methods: {
    validateForm() {
      return this.$refs["provider-billing-form"].validate();
    },
  },
};
</script>

<style scoped></style>
