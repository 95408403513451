var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"light-yellow-table-headings",attrs:{"hover":"","responsive":"","fixed":"","items":_vm.orders,"fields":_vm.fields,"no-local-sorting":true},on:{"row-clicked":function($event){return _vm.$router.push({ name: 'viewOrder', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"cell(pm_comments)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"cell(order_date)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"cell(number)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"cell(provider)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? value.name : '')+" ")]}},{key:"cell(total_gross_cy)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" € ")]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('StatusBadge',{attrs:{"status":value.name}})]}},{key:"cell(actions)",fn:function(data){return [_c('b-link',{staticClass:"d-inline-block text-danger pr-1",on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }