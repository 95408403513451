<template>
  <validation-observer ref="order-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <!-- <b-col sm="12">
              <b-form-group
                label="Job"
                label-for="order-job"
              >
                <BaseSelect
                  id="order-job"
                  :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
                  :value="model.job"
                  @input="handleJobSelectionChange"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="División"
                rules="required"
              >
                <b-form-group
                  label="División"
                  label-for="order-division"
                >
                  <BaseSelect
                    id="order-division"
                    :value="model.division"
                    :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
                    http-method="get"
                    @input="update('division', $event)"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col sm="12">
              <b-form-group
                label="Fecha pedido"
                label-for="order-date"
              >
                <b-form-datepicker
                  id="order-date"
                  :value="model.order_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha pedido"
                  :label-help="null"
                  @input="update('order_date', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Categoría"
                rules="required"
              >
                <b-form-group
                  label="Categoría"
                  label-for="order-category-group"
                >
                  <CategoryGroupSelect
                    id="order-category-group"
                    :value="model.category_group"
                    :state="$getValidationState(validationContext)"
                    @input="update('category_group', $event)"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col sm="12">
              <b-form-group
                label="Estado"
                label-for="order-status"
              >
                <StatusSelect
                  id="order-status"
                  :value="model.status"
                  :type="statusType"
                  @input="update('status', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Proveedor"
                  label-for="order-provider"
                >
                  <BaseSelect
                    id="offer-provider"
                    :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
                    :value="model.provider"
                    @selected="handleProviderSelectionChange"
                  />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Forma de pago"
                label-for="order-type-payment"
              >
                <BaseSelect
                  id="order-type-payment"
                  :resource="$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS"
                  :value="model.type_payment"
                  @input="update('type_payment', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                  label="Anotaciones PM"
                  label-for="pm_comments"
              >
                <b-form-textarea
                    :value="model.pm_comments"
                    name="pm_comments"
                    placeholder="Anotaciones PM"
                    rows="4"
                    max-rows="4"
                    @input="update('pm_comments', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                  label="Observaciones"
                  label-for="order-observations"
              >
                <b-form-textarea
                    :value="model.observations"
                    name="order-observations"
                    placeholder="Observaciones"
                    rows="4"
                    max-rows="4"
                    @input="update('observations', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Adjuntar Archivos">
                <FileDropZone
                  ref="documentation-input"
                  :files="files"
                  @removed-file="removedFiles.push($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { ORDERS_STATUS_TYPE } from '@/api/status-api'
import CategoryGroupSelect from '@/components/category/select/CategoryGroupSelect.vue'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'OrderForm',
  components: {
    BaseSelect,
    FileDropZone,
    StatusSelect,
    CategoryGroupSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: ORDERS_STATUS_TYPE,
      removedFiles: [],
    }
  },
  computed: {
    files() {
      return this.model.documentation
    },
  },
  methods: {
    validateForm() {
      return this.$refs['order-form'].validate()
    },
    getDocumentationData() {
      return { documentation: this.$refs['documentation-input'].getFiles(), removedFiles: this.removedFiles }
    },
    handleProviderSelectionChange(provider) {
      if (!provider) {
        this.update('provider')
        return
      }

      this.massUpdate({ ...this.model, provider, type_payment: provider.type_payment })
    },
    handleJobSelectionChange(job) {
      if (!job) {
        this.update('job', job)
        return
      }

      this.massUpdate({ ...this.model, division: job.division, job })
    },
  },
}
</script>
