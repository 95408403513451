var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"purchase-invoices-table",attrs:{"resource":"purchase-invoices","resourceStore":'purchaseInvoice',"columns":_vm.columns,"filters":_vm.baseFilters,"hide-toolbar":_vm.hideToolbar,"totals-configuration":_vm.totalsConfiguration,"loading":_vm.loading || _vm.localLoading,"disable-pagination":_vm.disablePagination,"excluded-filters":_vm.baseExcludeFilters},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"row-clicked":function($event){return _vm.$router.push({
        name: 'viewPurchaseInvoice',
        params: { id: $event.id },
      })},"on-clear-active-filters":_vm.handleClearListFilters,"data-loaded":function($event){return _vm.$emit('data-loaded', $event)}},scopedSlots:_vm._u([{key:"cell(job)",fn:function(ref){
      var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value ? value.code : ""))])]}},{key:"cell(provider)",fn:function(ref){
      var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value.name))])]}},{key:"cell(invoice_date)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.expiration_date),expression:"item.expiration_date"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(item.expiration_date))+" ")])]}},{key:"cell(expiration_date)",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          item.status.name === 'Pendiente de pago' ||
          item.status.name === 'Pago parcial'
        ),expression:"\n          item.status.name === 'Pendiente de pago' ||\n          item.status.name === 'Pago parcial'\n        "}],class:(_vm.isDueDateExpired(value) ? 'text-danger' : 'text-primary') +
          ' text-uppercase'},[_vm._v(" "+_vm._s(_vm.getDueDateDaysText(value))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.item.status.name,"text":data.item.status.name === 'Pago parcial' ?
          ("Pago parcial " + (data.item.percent !== 0 && data.item.percent !== 100 ? ((data.item.percent) + " % ") : ''))
        : null}}),(data.item.send_payment_document && data.item.send_payment_document === 1)?_c('StatusBadge',{staticClass:"mt-1",attrs:{"status":'Envio doc. pago',"text":'Envio doc. pago',"variant":'light-success'}}):_vm._e()]}},{key:"cell(delivery_note_received)",fn:function(data){return [_c('b-badge',{staticClass:"px-1 d-inline-flex align-items-center",attrs:{"pill":"","variant":data.item.delivery_note_received > 0
            ? 'light-success'
            : 'light-dark'}},[_c('feather-icon',{attrs:{"icon":data.item.delivery_note_received > 0
              ? 'CheckCircleIcon'
              : 'XCircleIcon',"size":"25","color":data.item.delivery_note_received > 0 ? 'success' : 'black'}}),_c('span',{class:'text-capitalize font-weight-light badge-text ' +
            (data.item.delivery_note_received > 0
              ? 'text-success'
              : 'text-dark')},[_vm._v(" "+_vm._s(data.item.delivery_note_received > 0 ? "Si" : "No")+" ")])],1)]}},{key:"cell(total_gross)",fn:function(ref){
              var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_invoice)",fn:function(ref){
              var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_liquid)",fn:function(ref){
              var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(value))+" €")])]}},{key:"cell(total_retention)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_retention))+" €")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(data.item.retention_expiration),expression:"data.item.retention_expiration"}],staticClass:"font-small text-light"},[_vm._v(" vence "+_vm._s(_vm._f("formatDate")(data.item.retention_expiration))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('span',[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(data.item.observations),expression:"data.item.observations"}],staticClass:"text-indigo mr-1",attrs:{"id":("popover-target-" + (data.item.id)),"icon":"InfoIcon","size":"18"}}),_c('b-popover',{attrs:{"target":("popover-target-" + (data.item.id)),"triggers":"hover","placement":"top"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Observaciones")]},proxy:true}],null,true)},[_c('pre',{staticStyle:{"background":"#ffff"}},[_vm._v(_vm._s(data.item.observations))])]),_c('b-link',{staticClass:"d-inline-block text-indigo",attrs:{"disabled":!data.item.documentation || data.item.documentation.length <= 0}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Descargar factura","icon":"DownloadIcon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.handlePrintIconClick(data.item)}}})],1),_c('b-link',{staticClass:"d-inline-block px-1 text-indigo",attrs:{"to":{
            name: 'viewPurchaseInvoice',
            params: { id: data.item.id },
          }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Ver","icon":"EyeIcon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
            resource: _vm.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
            resourceAction:
              _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,
          }),expression:"{\n            resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,\n            resourceAction:\n              $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL,\n          }"}],staticClass:"d-inline-block text-danger"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.deletePurchaseOrder(data.item)}}})],1)],1)]}},{key:"footer",fn:function(ref){
          var data = ref.data;
return [(_vm.footerTotalsVisible)?_c('b-tr',{staticClass:"bg-action-blue"},[_c('b-td',{attrs:{"colspan":"6"}}),_c('b-td',{staticClass:"text-right text-uppercase font-weight-bold"},[_vm._v(" Totales: ")]),_c('b-td',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(data.totals.total_gross || 0))+" € ")]),_c('b-td',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(data.totals.total_retention || 0))+" € ")]),_c('b-td',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(data.totals.total_liquid || 0))+" € ")]),_c('b-td',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(data.totals.total_invoice || 0))+" € ")]),_c('b-td',{attrs:{"colspan":"1"}}),_c('b-td',{attrs:{"colspan":"1"}})],1):_vm._e()]}}])}),(!_vm.hideToolbar)?_c('PurchaseInvoicesListFilters',{ref:"purchase-invoices-list-filters",on:{"filters-submit":function($event){return _vm.$store.commit('purchaseInvoice/setFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }