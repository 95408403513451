<template>
  <div>
    <BaseTable
      ref="certification-reports-table"
      :columns="columns"
      :resource="$data.$constants.RESOURCES.RESOURCE_CERTIFICATION_REPORT"
      :loading="loading"
      :filters="filters"
      :totals-configuration="totalsConfiguration"
      hide-printer-button
      @data-loaded="$emit('data-loaded', $event)"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
    >
      <template #cell(job)="data">
        {{ data.value ? data.value.code : "" }}
      </template>
      <template #cell(status)="data">
        <StatusBadge
          v-if="data.item.status"
          :text="data.item.status.name"
          :status="data.item.status.name"
        />
      </template>
      <template #cell(sale_value)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(pm_cost)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(invoices_issued_to_origin)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(purchase_invoices)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(sale_invoices)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(billing_to_be_issued)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(minimum_billing_objective_month_per_advancee)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(certification_value_real_month_pm)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(advanced_percentage)="data">
        {{ data.value | numberToLocalString }} %
      </template>
      <template #cell(comments)="data">
        <span v-if="!rowsEditionEnabled[data.index]">
          {{ data.value }}
        </span>
        <div v-else>
          <b-form-textarea v-model="data.item.comments" />
        </div>
      </template>
      <template #cell(actions)="data">
        <b-link
          v-if="!rowsEditionEnabled[data.index]"
          class="d-inline-block text-indigo pr-1"
          @click="handleEditIconClick(data)"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Editar"
            icon="Edit2Icon"
            size="18"
          />
        </b-link>
        <div class="d-flex" v-else>
          <b-link
            class="d-inline-block text-indigo pr-50"
            @click="handleSaveCertificationIconClick(data.item.id, data.item.comments, data.index)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Guardar certificacion"
              icon="SaveIcon"
              size="18"
            />
          </b-link>
          <b-link
            class="d-inline-block text-danger pr-1"
            @click="handleCancelEditionIconClick(data)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Cerrar"
              icon="SlashIcon"
              size="18"
            />
          </b-link>
        </div>
      </template>
    </BaseTable>
    <CertificationReportsListFilters
      ref="certification-reports-list-filters"
      v-model="filtersVisible"
      @filters-submit="filters = $event"
    />
  </div>
</template>

<script>
import Vue from "vue";
import BaseTable from "@/components/ui/table/BaseTable.vue";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import CertificationReportsListFilters from "@/components/certification-reports/filters/CertificationReportsListFilters.vue";
import CertificationsApi from "@/api/certifications-api";

export default {
  name: "CertificationReportsTable",
  components: {
    CertificationReportsListFilters,
    StatusBadge,
    BaseTable,
  },
  data() {
    return {
      loading: false,
      filters: {},
      filtersVisible: false,
      rowsEditionEnabled: [],
      totalsConfiguration: [
        { colspan: 12 },
        { label: "Total certificado", key: "total_certified", unit: "€" },
      ],
      columns: [
        {
          label: "Fecha",
          key: "certification_date",
          sortable: true,
          thStyle: { "min-width": "100px" },
        },
        {
          label: "JOB",
          key: "job",
          sortable: true,
          thStyle: { "min-width": "80px" },
        },
        {
          label: "Cliente",
          key: "client",
          sortable: true,
        },
        {
          label: "PM",
          key: "job_user.full_name",
          sortable: true,
          thStyle: { "min-width": "150px" },
        },
        {
          label: "ESTADO",
          key: "status",
          sortable: true,
        },
        {
          label: "Valor venta",
          key: "sale_value",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
          thStyle: { "min-width": "110px" },
        },
        {
          label: "AVANCE JOB",
          key: "advanced_percentage",
          sortable: true,
          thStyle: { "min-width": "80px" },
        },
        {
          label: "Facturas Emitidas",
          key: "sale_invoices",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
          thStyle: { "min-width": "110px" },
        },
        // {
        //   label: 'Coste PM',
        //   key: 'pm_cost',
        //   thClass: 'text-right',
        //   tdClass: 'text-right',
        //   sortable: false,
        //   thStyle: { 'min-width': '110px' },
        // },
        // {
        //   label: 'Facturas Recibidas',
        //   key: 'purchase_invoices',
        //   thClass: 'text-right',
        //   tdClass: 'text-right',
        //   sortable: false,
        //   thStyle: { 'min-width': '110px' },
        // },
        {
          label: "Facturación por emitir",
          key: "billing_to_be_issued",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
          thStyle: { "min-width": "110px" },
        },
        // {
        //  label: 'Objetivo facturación a origen por avance',
        // key: 'target_billing_to_source_by_progress',
        // thClass: 'text-right',
        //  tdClass: 'text-right',
        //  sortable: false,
        // thStyle: { 'min-width': '110px' },
        //},
        {
          label: "Objetivo facturación mínima MES por avance (IAS)",
          key: "minimum_billing_objective_month_per_advancee",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
          thStyle: { "min-width": "110px" },
        },
        {
          label: "Valor Certificación REAL MES PM",
          key: "certification_value_real_month_pm",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: false,
          thStyle: { "min-width": "110px" },
        },
        {
          label: "Comentarios",
          key: "comments",
          sortable: false,
          thStyle: { "min-width": "250px" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: { 'min-width': '80px' },
        },
      ],
    };
  },
  methods: {
    loadData() {
      return this.$refs["certification-reports-table"].loadData();
    },
    async handleSaveCertificationIconClick(id, comments, index) {
      this.loading = true;
      try {
        await CertificationsApi.edit(id, { comments }, false);
        await this.loadData();
        this.rowsEditionEnabled[index] = false;
        this.$toast("Recurso actualizado con éxito.");
      } catch (error) {
        console.log(error)
        this.$toast.error(
          "Ocurrió un error al actualizar el recurso. Por favor inténtelo de nuevo mas tarde."
        );
      }
      this.loading = false;
    },
    handleEditIconClick(row) {
      Vue.set(this.rowsEditionEnabled, row.index, true);
    },
    handleCancelEditionIconClick(row) {
      Vue.set(this.rowsEditionEnabled, row.index, false);
    },
    handleClearListFilters() {
      this.filters = {};
      this.$refs["certification-reports-list-filters"].clearFilters();
    },
  },
};
</script>

<style scoped>
.bill-button {
  width: 140px;
}
</style>
