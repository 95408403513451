<template>
  <b-tabs pills>
    <b-tab
      :disabled="!contactsTabVisible"
      title="Contactos"
    >
      <BaseCard
        v-if="contactsTabVisible"
        title="pesonas de contacto"
        :footer-visible="false"
        actions-visible
      >
        <template #actions>
          <b-link :to="{ name: 'createContact', query: { 'provider-id': providerId } }">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir persona de contacto
            </b-button>
          </b-link>
        </template>
        <ContactsTable :provider-id="providerId" />
      </BaseCard>
    </b-tab>
    <b-tab
      title="Pedidos"
      :disabled="!ordersTabVisible"
    >
      <BaseCard
        v-if="ordersTabVisible"
        title="pedidos"
        :footer-visible="false"
      >
        <OrdersTable
          :provider-id="providerId"
          :totals-configuration="orderTotalsConfiguration"
        />
      </BaseCard>
    </b-tab>
    <b-tab
      title="Albaranes"
      :disabled="!deliveryNotesTabVisible"
    >
      <BaseCard
        v-if="deliveryNotesTabVisible"
        title="albaranes"
        :footer-visible="false"
      >
        <DeliveryNotesTable
          :provider-id="providerId"
          :totals-configuration="deliveryNotesTotalsConfiguration"
        />
      </BaseCard>
    </b-tab>
    <b-tab
      title="Facturas"
      :disabled="!purchaseInvoicesTabVisible"
    >
      <BaseCard
        v-if="purchaseInvoicesTabVisible"
        title="facturas"
        :footer-visible="false"
      >
        <PurchaseInvoicesTable
          :provider-id="providerId"
          :totals-configuration="purchaseInvoiceTotalsConfiguration"
        />
      </BaseCard>
    </b-tab>
  </b-tabs>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import OrdersTable from '@/components/orders/table/OrdersTable.vue'
import ContactsTable from '@/components/contacts/table/ContactsTable.vue'
import DeliveryNotesTable from '@/components/delivery-notes/table/DeliveryNotesTable.vue'
import PurchaseInvoicesTable from '@/components/purchase-invoices/table/PurchaseInvoicesTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProviderAssociatedResourceTabs',
  components: {
    PurchaseInvoicesTable,
    DeliveryNotesTable,
    OrdersTable,
    ContactsTable,
    BaseCard,
  },
  props: {
    providerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      deliveryNotesTotalsConfiguration: [
        { colspan: 3 },
        { label: 'TOTAL', key: 'total_delivery_notes' },
        { colspan: 2 },
      ],
      purchaseInvoiceTotalsConfiguration: [
        { colspan: 6 },
        { label: 'TOTAL BRUTO', key: 'total_gross' },
        { label: 'TOTAL', key: 'total_invoice' },
        { label: 'TOTAL RETENCIÓN', key: 'total_retention' },
        { colspan: 1 },
      ],
      orderTotalsConfiguration: [
        { colspan: 6 },
        { label: 'TOTAL BRUTO', key: 'total_gross' },
        { label: 'TOTAL PEDIDO', key: 'total_order' },
        { colspan: 1 },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', {
      canAccessResource: 'canAccessResource',
    }),
    contactsTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_CONTACTS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
    ordersTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_ORDERS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
    deliveryNotesTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
    purchaseInvoicesTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
  },
  created() {
    this.handleClearListFilters()
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit('job/setFilters', null)
      this.$store.commit('job/setSearch', null)
      this.$store.commit('orders/setFilters', null)
      this.$store.commit('orders/setSearch', null)
      this.$store.commit('purchaseInvoice/setSearch', null)
      this.$store.commit('purchaseInvoice/setFilters', null)
    },
  },
}
</script>

<style scoped>

</style>
