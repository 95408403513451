<template>
  <BaseModal
    v-model="model"
    title="Nuevo cliente"
    size="xl"
    :loading="loading"
    @confirm-button-click="handleSaveButtonClick"
  >
    <b-card-title class="m-0 pt-1 px-2">
      <b-row align-v="center">
        <b-col
          class="font-weight-bold d-flex align-items-center"
          cols="auto"
        >
          PERFIL
        </b-col>
      </b-row>
    </b-card-title>
    <ClientProfileForm
      ref="client-profile-form"
      v-model="client"
    />
    <b-card-title class="m-0 pt-1 px-2">
      <b-row align-v="center">
        <b-col
          class="font-weight-bold d-flex align-items-center"
          cols="auto"
        >
          INFORMACIÓN BANCARIA Y DE PAGO
        </b-col>
      </b-row>
    </b-card-title>
    <ClientBillingForm
      ref="client-billing-form"
      v-model="client"
    />
  </BaseModal>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ClientProfileForm from '@/components/clients/form/ClientProfileForm.vue'
import ClientBillingForm from '@/components/clients/form/ClientBillingForm.vue'
import BaseModal from '@/components/ui/modal/BaseModal.vue'

export default {
  name: 'ClientCreationModal',
  components: { BaseModal, ClientBillingForm, ClientProfileForm },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      client: {},
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    profileForm() {
      return this.$refs['client-profile-form']
    },
    billingForm() {
      return this.$refs['client-billing-form']
    },
  },
  methods: {
    async handleSaveButtonClick() {
      const profileValid = await this.profileForm.validateForm()
      const billingValid = await this.billingForm.validateForm()

      if (!profileValid || !billingValid) {
        return
      }

      this.loading = true
      try {
        const response = await ApiRestService.create(this.client, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        const newClient = response.data.data
        this.$toast('Empresa cliente creada con éxito')
        this.$emit('input', false)
        this.profileForm.clearForm()
        this.billingForm.clearForm()
        this.$emit('client-created', newClient)
      } catch (error) {
        this.$toast.error('Ocurrio un error al crear la empresa cliente. Por favor inténtelo de nuevo mas tarde.')
        console.error(error)
      }

      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
